.goBack {
  border: none;
  color: #008d99;
  background-color: white;
  font-size: 1.5em;
  margin-bottom: 1em;
}
.goBack:hover {
  cursor: pointer;
}

#Header {
  display: flex;
  position: absolute;
  justify-content: space-between;
  top: 0px;
  height: 4em;
  width: 100vw;
  // background-color: #97a133;
  border-bottom: solid 0.2em #97a133;
  color: white;
  .sideImage {
    height: 4em;
  }
  .right {
    margin-right: 1em;
  }
  .left {
    margin-left: 1em;
  }

  a {
    margin-top: 0.5em;
    margin-left: 1em;
    color: white;
    text-decoration: none;
    img {
      height: 3em;
    }
  }
  a:hover {
    text-decoration: underline;
  }
}

#Login {
  margin-top: 8em;
  display: flex;
  justify-content: center;
  .inputSection {
    p {
      color: #ce4553;
      font-size: 0.8em;
    }
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 30em;
    border: solid 0.1em black;
    border-radius: 0.2em;
    box-shadow: 0.1em 0.1em;
    h2 {
      width: 80%;
      border-bottom: solid 0.1em #97a133;
    }
    input {
      width: 80%;
      height: 1.5em;
    }
    .incomplete {
      border: solid 0.1em red;
    }
    button {
      font-size: 1em;
      width: 20%;
      border: none;
      border-radius: 0.1em;
      padding: 0.5em;
      background-color: #ce4553;
    }
  }
}

#Home {
  // background-color: #808285;
  // display: flex;

  margin-top: 4em;
  padding: 2em 4em;
  #logOut {
    float: right;
    width: 10em;
    color: white;
    background-color: #ce4553;
    border: none;
    padding: 0.5em;
    border-radius: 0.2em;
    font-size: 1.2em;
  }
  #logOut:hover {
    cursor: pointer;
  }
  // @media screen and(min-width: 40em) {
  //   flex-direction: row;
  // }
  .incomplete {
    border: solid 0.1em red;
  }
  .errorMessage {
    color: #ce4553;
    font-size: 2em;
  }
  input {
    width: 80%;
    height: 1.5em;
    margin-bottom: 1em;
  }
  span {
    margin-bottom: 1em;
    line-height: 2em;
    .inputSection {
      width: 20em;
      background-color: white;
      padding: 1em;
      margin-bottom: 2em;
      border-radius: 0.5em;
      border: solid 0.1em black;
      h2 {
        border-bottom: solid 0.1em #97a133;
      }
      button {
        font-size: 1.5em;
        border: none;
        border-radius: 0.1em;
        color: white;
        padding: 0.5em;
        background-color: #ce4553;
      }
      button:hover {
        cursor: pointer;
      }
    }
  }
  a {
    color: white;
    background-color: aquamarine;
  }
  .groupBox {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    // width: 35em;
    border: solid 0.1em black;
    border-radius: 0.5em;
    background-color: white;
    padding: 2em;
    h2 {
      border-bottom: solid 0.1em #97a133;
    }
    .buttonBox {
      // display: flex;
      justify-content: space-evenly;
    }
    button {
      background-color: #008d99;
      font-size: 0.8em;
      padding: 2em;
      margin: 0.5em;
      border: none;
      border-radius: 0.5em;
      box-shadow: 0.1em 0.2em;
      font-size: 0.9em;
    }
    button:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

.certificate {
  font-size: 1em;
  height: 4em;
  font-size: 1.5em;

  color: white;
  border: none;
  border-radius: 0.1em;
  padding: 0.5em;
  background-color: #ce4553;
}
.certificate:hover {
  cursor: pointer;
}

.IdActivity {
  // background-color: #808285;
  font-size: 0.9em;
  text-align: center;
  margin-top: 4em;
  padding: 4em;

  span {
    h2 {
      color: #008d99;
      font-weight: 800;
    }
  }
  ul {
    list-style-type: none;
    h2 {
      color: #008d99;

      position: relative;
      top: -0.6em;
    }
  }
  div {
    display: flex;
    justify-content: space-between;
    width: 70vw;
    margin: 1em auto;
    border-bottom: solid 0.1em #008d99;
  }
  .badgeList {
    h2 {
      text-align: left;
    }
    width: 40em;
    li {
      font-weight: 900;
      text-align: left;
    }
    span {
      font-weight: 300;

      margin-bottom: 10em;
    }
  }
  .certificate {
    margin-bottom: 1em;
  }
}

#GroupActivity {
  // background-color: #808285;
  margin-top: 5em;
  .contain {
    font-size: 0.5em;
    text-align: center;
    padding: 0 2em;
  }

  .groupList {
    width: 80vw;
    margin: 2em auto;
  }

  .participant {
    display: flex;
    justify-content: space-between;
    margin: 1em auto;
    font-size: 1.2em;
    width: 60vw;
    border-bottom: solid 0.1em #008d99;
    span{
      width: 20vw;
    }
  }

  .certificate {
    font-size: 0.8em;
    height: 3em;
    padding: 0 2em;
    margin: 0 0 1em 3em;
  }
  span {
    min-width: 15em;
  }
  .goBack {
    margin-left: 10em;
  }
}

#Footer {
  background-color: white;
  position: sticky;
  display: flex;
  justify-content: space-between;
  padding: 3em;
  bottom: 1em;
}

.spinner {
  height: 15em;
  margin-top: 10em;
}
.button-spinner {
  height: 2em;
  background-color: transparent;
}
.button-spinner .home-page {
  visibility: hidden;
}

.spinnerHolder {
  width: 100%;
  height: 5vh;
  display: flex;

  justify-content: center;
}

#notFound {
  margin-top: 6em;
}
@import "_queries";
