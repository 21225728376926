@import url("https://fonts.googleapis.com/css2?family=Newsreader:ital@1&display=swap");

.certWrap {
  // font-size: 0.9em;
  // text-align: center;
  // margin-top: 2em;
  // padding: 2em;

  span {
    h2 {
      color: #008d99;
      font-weight: 800;
    }
  }
  ul {
    list-style-type: none;
    h2 {
      color: #008d99;
      position: relative;
      top: -0.6em;
    }
  }
}
.mb5 {
  display: flex;
  justify-content: space-between;
  // margin: 2em;
  margin: 1em auto 1em auto;
  width: 70vw;
  border-bottom: solid 0.1em #008d99;
}

.print {
  color: #ce4654;
  width: 10em;
  font-size: 1.5em;
  background-color: white;
  border: none;
}
.print:hover {
  cursor: pointer;
}

#divToPrint {
  position: relative;
  top: 0%;
  // left: 5%;
  width: 973.991px;
  margin: 0 auto;
  height: 765px;
  padding: 2em;
  table {
    position: relative;
    top: -750px;
    z-index: 2;
    // height: 100%;
    // width: 970.909px;
    background-repeat: no-repeat;
    background-size: 100%;
    td {
      max-width: 121px;
    }
  }
  td img {
    width: 60%;
  }
}
.hide {
  display: none;
}
// .mt4 {
//   height: 100%;
//   width: 970.909px;
// }

.participant_name {
  float: left;
  width: 400%;
  font-family: "Newsreader", serif;
  font-size: 2em;
}

.certificate-page {
  text-align: center;
  margin-top: 8em;
  .backHolder {
    display: flex;
    justify-content: space-between;
    margin: 1em auto 0 auto;
    width: 70vw;
    border-bottom: solid 0.1em #008d99;
    .goBack {
      padding: 0;
      margin-bottom: 0;
    }
  }

  .facilitator_name {
    width: 250%;
    font-family: "Newsreader", serif;
    font-size: 1.5em;
    margin-bottom: 1em;
  }
}
.date {
  font-family: "Newsreader", serif;
  font-size: 1.5em;
  margin-left: -1em;
  margin-bottom: 1em;
}
.adjust {
  max-width: 121px;
}
